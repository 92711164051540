// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-hospital-index-js": () => import("./../../../src/templates/hospital-index.js" /* webpackChunkName: "component---src-templates-hospital-index-js" */),
  "component---src-templates-hospital-js": () => import("./../../../src/templates/hospital.js" /* webpackChunkName: "component---src-templates-hospital-js" */),
  "component---src-templates-package-js": () => import("./../../../src/templates/package.js" /* webpackChunkName: "component---src-templates-package-js" */),
  "component---src-templates-procedure-js": () => import("./../../../src/templates/procedure.js" /* webpackChunkName: "component---src-templates-procedure-js" */)
}

